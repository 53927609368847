import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { CatalogIsReadOnlyService } from '@bend/shared-widgets/src/lib/shared/catalog-services';
import { ItemUnavailabilityDisplay } from '@bend/store';

import { PopCurrency, PopSettings } from '../../widget';
import { isTemporarilyUnavailable } from '../helpers';
import {
  PopMenuCategory,
  PopOptionGroup,
  PopProduct,
  PopProductDetailsDialogParams,
  PRICE_DISPLAY_MODE,
  WidgetProductCatalog,
} from '../product-catalog';
import { WidgetProductCatalogHelperService } from '../product-catalog-helper.service';
import { WidgetProductCatalogLayoutService } from '../product-catalog-layout.service';

@Component({
  selector: 'pop-product-catalog-menu-details-dialog',
  templateUrl: '../product-details-dialog/product-details-dialog.component.html',
  styleUrls: ['../product-details-dialog/product-details-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WidgetProductCatalogMenuDetailsDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('fixedEl') fixedEl: ElementRef;
  @ViewChild('fakeFooterEl') fakeFooterEl: ElementRef;
  @ViewChild('contentWrapper') contentWrapperEl: ElementRef;
  @ViewChild('scrollIndicator') scrollIndicatorEl: ElementRef;
  product: PopProduct;
  attributes: WidgetProductCatalog;
  errorMessages: number[];
  optionGroupQuantityError: number[];
  showErrors: boolean;
  total: number;
  isMenu: boolean;
  priceDisplayModeEnum = PRICE_DISPLAY_MODE;
  currency: PopCurrency;
  fullPhoto: boolean;
  isReadOnlyMenu: boolean;
  quantity: number;
  category?: PopMenuCategory;
  isKitchenClosed$: Observable<boolean>;

  // TD StrictTemplates (Serghei)
  isLoading: boolean;
  isCommentToKitchenInvalid: boolean;
  isCommentToKitchenRequired: boolean;
  itemUnavailabilityDisplay: ItemUnavailabilityDisplay;
  itemUnavailabilityDisplayEnum = ItemUnavailabilityDisplay;
  hasFullPriceOptions: boolean;
  commentToKitchen: string;

  showBackdrop = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PopProductDetailsDialogParams,
    private _helperService: WidgetProductCatalogHelperService,
    private _layoutService: WidgetProductCatalogLayoutService,
    private _dialogRef: MatDialogRef<WidgetProductCatalogMenuDetailsDialogComponent>,
    private _catalogIsReadOnly: CatalogIsReadOnlyService,
  ) {
    this.category = this.data.category;
    this.errorMessages = [];
    this.optionGroupQuantityError = [];
  }

  ngOnInit(): void {
    this.product = this.data.product;
    this._helperService.sortOptionsByOrder(this.product);

    this.quantity = this.product.quantityMultiplier;
    this.attributes = this.data.attributes;
    this.currency = this.attributes.currency;
    this.isReadOnlyMenu = this.data.isReadOnlyMenu;
    if (!this.product.isSelected) {
      this._helperService.selectDefaultOptions(this.product);
    } else {
      this._resetNotSelectedOptionsQuantity(this.product);
    }
    this._helperService.selectDefaultSubProduct(this.product);
    this._updateTotal();
    this.isMenu = this.data.isMenu;
    this._addMissingAttributes();
    this.isKitchenClosed$ = this._catalogIsReadOnly.getIsClose(this.product.catalogId).pipe(distinctUntilChanged());
    this.itemUnavailabilityDisplay = this.data.attributes.itemUnavailabilityDisplay;
  }

  ngAfterViewInit(): void {
    this._layoutService.setFakeFooterHeightFromFixedPositionedEl(this.fakeFooterEl, this.fixedEl);
  }

  pick(): void {
    this.showErrors = false;

    this.errorMessages = this._helperService.getAllRequiredOptionsNotSelected(this.product);
    if (this.errorMessages.length) return;

    if (this.product.children?.length && !this.isSelectedSubProduct()) {
      this.showErrors = true;
      return;
    }

    this.optionGroupQuantityError = this._helperService.getOptionGroupsWithQuantityError(this.product);
    if (this.optionGroupQuantityError.length) {
      this.showErrors = true;
      return;
    }

    this.product.quantity = this.quantity;
    this._dialogRef.close(this.product);
  }

  selectOption(option: PopProduct, optionGroup: PopOptionGroup): void {
    if (!option.available || isTemporarilyUnavailable(option)) return;

    this._helperService.selectOption(option, optionGroup);
    this._updateTotal();
  }

  selectSubProduct(subProductId: number): void {
    this._helperService.toggleSubProduct(subProductId, this.product);
    this._updateTotal();
  }

  isSelectedSubProduct(): boolean {
    return this._helperService.isSelectedSubProduct(this.product);
  }

  decreasesOptionQuantity(option: PopProduct): void {
    if (option.quantityMultiplier) {
      option.quantityMultiplier -= 1;
    }
    if (!option.quantityMultiplier) {
      option.isSelected = false;
    }
    this._updateTotal();
  }

  addOptionQuantity(option: PopProduct, optionGroup: PopOptionGroup): void {
    if (
      optionGroup.maxAllowedOptions &&
      this._helperService.selectedOptionsCount(optionGroup) >= optionGroup.maxAllowedOptions
    ) {
      return;
    }
    option.quantityMultiplier += 1;
    if (option.quantityMultiplier) {
      option.isSelected = true;
    }
    this._updateTotal();
  }

  makeFullPhoto(): void {
    this.fullPhoto = !this.fullPhoto;
  }

  incrementQuantity(): void {
    if (this.category.allowMultiple) {
      const selectedQuantity = this.category.menuCategoryItems.reduce(
        (acc, curr) => acc + (curr.item.quantity || 0),
        0,
      );

      if (selectedQuantity + this.quantity === this.category.max) return;
    } else {
      if (this.quantity === this.category.max) return;
    }

    this.quantity += this.product.quantityMultiplier;
    this._updateTotal();
  }

  decrementQuantity(): void {
    if (this.quantity > this.product.quantityMultiplier) {
      this.quantity -= this.product.quantityMultiplier;
    }
    this._updateTotal();
  }

  // TODO(Serghei) add method for fix strict templates
  scrollToAddCommentToKitchen(scrollToPosition: number): void {
    const contentWrapperNativeEl = this.contentWrapperEl.nativeElement;
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    contentWrapperNativeEl.scrollTop = scrollToPosition + contentWrapperNativeEl.scrollTop;
  }

  // TODO(Serghei) add method for fix strict templates
  onCommentChanged(comment: string): void {
    this.commentToKitchen = comment;
  }

  private _updateTotal(): void {
    this.total = this._helperService.calculateProductTotal(this.product, this.quantity);
  }

  private _addMissingAttributes(): void {
    if (!this.attributes) {
      return;
    }

    if (!this.attributes.pop) {
      this.attributes.pop = new PopSettings();
    }
  }

  private _resetNotSelectedOptionsQuantity(product: PopProduct): void {
    product.itemOptionGroups.forEach(group => {
      group.optionGroup.optionGroupSubitems.forEach(subitem => {
        if (!subitem.item.isSelected && group.optionGroup.allowMultipleSameOptions) {
          subitem.item.quantityMultiplier = 0;
        }
      });
    });
  }
}
