import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import { Epson } from './epos-2.17.0.types';
import { Job, JobData } from './type';

@Injectable()
export class EPosService {
  private _printer: Epson['ePOSDevice'];
  private _device: any;

  private _jobs: Map<string, Job>;
  private _waitingJob: boolean;

  constructor() {
    this._printer = new window.epson.ePOSDevice();

    this._jobs = new Map();
    this._waitingJob = false;
  }

  connect(ip: string, port?: string | number): Observable<this> {
    return new Observable((observer: Observer<this>) => {
      const protocol = window.location.protocol;
      port = port ?? (/^(https:)/.exec(protocol) ? this._printer.IFPORT_EPOSDEVICE_S : this._printer.IFPORT_EPOSDEVICE);

      this._printer.connect(ip, port, (connect: string) => {
        if (connect === 'OK' || connect === 'SSL_CONNECT_OK')
          this._createDevice(
            () => observer.next(this),
            err => observer.error(err),
          );
        else observer.error(connect);
      });
    });
  }

  print(id: string, data: JobData): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this._jobs.set(id, { ...data, observer });

      this._printJob();
    });
  }

  private _createDevice(callBack: () => void, callBackErr: (err: string) => void): void {
    const deviceType = this._printer.DEVICE_TYPE_PRINTER;
    const deviceId = 'local_printer';

    this._printer.createDevice(
      deviceId,
      deviceType,
      { crypto: false, buffer: false },
      (device: any | null, code: string) => {
        if (code === 'OK') {
          this._device = device;
          callBack();
        } else {
          callBackErr(code);
        }
      },
    );
  }

  private _printJob(): void {
    if (
      /**
       * check if exist jobs
       */
      !this._jobs.size ||
      /**
       * waiting to finish precedent job
       */
      this._waitingJob
    )
      return;

    this._waitingJob = true;

    const jobId = this._jobs.keys().next().value as string;
    const job = this._jobs.get(jobId) ;
    if ('message' in job) {
      this._device.addText(`${job.message}\n`);
      this._device.addCut();
    } else {
      job.render(this._device);
    }
    this._device.onreceive = () => {
      this._jobs.delete(jobId);
      job.observer.next(jobId);
      job.observer.complete();
      /**
       * print next job
       */
      this._waitingJob = false;
      this._printJob();
    };
    this._device.onerror = (err: any) => console.error(err.status);
    this._device.send(jobId);
  }
}
