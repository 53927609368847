import { Component, Input } from '@angular/core';

@Component({
  selector: 'pop-widget-product-catalog-subcategory',
  templateUrl: './product-catalog-subcategory.component.html',
  styleUrls: ['./product-catalog-subcategory.component.scss'],
})
export class WidgetProductCatalogSubcategoryComponent {
  @Input() subcategoryName: string;
  @Input() subcategoryDescription: string;
  @Input() mainColor: string;
  @Input() secondColor: string;
  @Input() styleType: string;
  @Input() styleNumber: number;
  subcategoryStyleType: string;
}
